import { createAsyncThunk } from '@reduxjs/toolkit';
import { container } from '../../../configs/inversify';
import { ILocalStorageService } from '../../services/LocalStorageService';
import { IAuthService } from './auth.service';
import { LoginFormFields } from './types';

const authService: IAuthService = container.get('AuthService');
const localStorageService: ILocalStorageService = container.get('LocalStorageService');

export const loginUser = createAsyncThunk('auth/login', async (credentials: LoginFormFields) => {
  const user = authService.login(credentials);
  localStorageService.setItem('login-credentials', credentials);
  return user;
});

export const logoutUser = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
});
