const { REACT_APP_API_PREFIX, REACT_APP_API_AUTH_PREFIX } = process.env;

export const API_LOGIN = `${REACT_APP_API_AUTH_PREFIX}/login`;
export const API_LOGOUT = `${REACT_APP_API_AUTH_PREFIX}/logout`;

export const API_POLYGON = `${REACT_APP_API_PREFIX}/polygons`;

export const API_GRIP_LATEST = `${REACT_APP_API_PREFIX}/grip`;
export const API_GRIP_BASELINE = `${REACT_APP_API_PREFIX}/baseline`;
export const API_GRIP_GROUND_TRUTH = `${REACT_APP_API_PREFIX}/grip/ground-truth`;

export const API_EXPORT_DATA_AS_CSV = `${REACT_APP_API_PREFIX}/grip/:layer/:region_id/export`;
