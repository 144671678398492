import React from 'react';
import { Filter } from '../Filter';
import { useDispatch, useSelector } from 'react-redux';
import { gripFilterSelector, resetGripFilter, setGripFilter } from '../../../../map/map.store';

export const FiltersWrapper: React.FC = () => {
  const gripFilter = useSelector(gripFilterSelector);
  const dispatch = useDispatch();

  const resetFilters = () => {
    handleResetGripFilter();
  };
  const handleResetGripFilter = () => {
    dispatch(resetGripFilter());
  };
  const updateGripFilters = (title: string, value: boolean): void => {
    dispatch(setGripFilter({ ...gripFilter, [title]: value }));
  };

  return (
    <>
      {/*<div className="controls-header">*/}
      {/*  Recent filters <i className="controls-header-icon icon icon-arrow" />*/}
      {/*</div>*/}
      {/*TODO: add filters later*/}
      <Filter
        filterOptions={{ arterial: true, collector: true, local: true }}
        title="Functional road class"
        updateFilterOption={() => ''}
        resetFilters={() => ''}
      />
      <Filter
        filterOptions={gripFilter}
        title="Grip rating"
        updateFilterOption={updateGripFilters}
        resetFilters={handleResetGripFilter}
      />
      <div className="controls-footer">
        {/*<button className="controls-footer-btn" disabled>*/}
        {/*  Save preset*/}
        {/*</button>*/}
        <button className="controls-footer-btn" onClick={resetFilters}>
          Reset
        </button>
      </div>
    </>
  );
};
