import React, { useEffect, useState } from 'react';
import tactile from '../../../../assets/img/tactile.png';
import logo from '../../../../assets/img/logo.png';

import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../../components/Dropdown/DropdownItem';
import { Toggler } from '../../../components/Toggler';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../../../constants/pathnames';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../auth/auth.thunks';
import { currentUserSelector } from '../../auth/auth.store';
import { UserMultipolygon } from '../../../../types';
import { selectedGripTypeSelector, selectedPolygonSelector, setSelectedPolygon } from '../../../store/toolbarOptions';
import './_header.scss';
import { IDataService } from '../../../services/DataService';
import { container } from '../../../../configs/inversify';
import { fetchPolygonData } from '../../map/map.thunks';
import { clearPolygonData, filtersSelector } from '../../map/map.store';
import { TogglerStilized } from '../../../components/TogglerStilized';

const headerList = [
  {
    name: 'Map',
    icon: 'map',
    active: true,
  },
  {
    name: 'Reports',
    icon: 'excel',
    active: false,
  },
  {
    name: 'Planning',
    icon: 'graph',
    active: false,
  },
  {
    name: 'Settings',
    icon: 'gear',
    active: false,
  },
];

const dataService: IDataService = container.get('DataService');

const Header: React.FC = () => {
  const [menuItems] = useState(headerList);
  const [areas, setAreas] = useState<UserMultipolygon[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserSelector);
  const selectedPolygon = useSelector(selectedPolygonSelector);
  const selectedGripType = useSelector(selectedGripTypeSelector);
  const filters = useSelector(filtersSelector);

  useEffect(() => {
    if (!selectedGripType || !selectedPolygon) return;
    dispatch(clearPolygonData());
    dispatch(
      fetchPolygonData({
        polygonId: selectedPolygon.id,
        dataType: selectedGripType.value,
      }),
    );
    const subscription = dataService.subscribeToDataChanges(selectedPolygon.id, () => {
      // TODO: improve with checking of the update type after all update types will be known
      dispatch(
        fetchPolygonData({
          polygonId: selectedPolygon.id,
          dataType: selectedGripType.value,
        }),
      );
    });

    return () => {
      dataService.unsubscribeFromDataChanges(subscription);
    };
  }, [selectedGripType, selectedPolygon]);

  useEffect(() => {
    if (!currentUser) return;
    setAreas(currentUser.multipolygon);
    if (currentUser.multipolygon.length) {
      dispatch(setSelectedPolygon(currentUser.multipolygon[0]));
    }
  }, [currentUser]);

  const onLogout = () => {
    dispatch(logoutUser());
    history.push(LOGIN);
  };

  const onSelectPolygon = (polygon: UserMultipolygon) => {
    if (!currentUser) return;
    dispatch(setSelectedPolygon(polygon));
  };

  const onClickSaveData = () => {
    if (selectedPolygon && selectedGripType) {
      dataService.exportAsCSV(selectedPolygon.id, selectedGripType.value, filters);
    }
  };

  return (
    <header className="header">
      <div className="d-flex">
        <img src={tactile} alt="tactile" className="header-logo-tactile" />
      </div>
      <ul className="header-list full-height ">
        {menuItems.map((item, index) => {
          return (
            <li className={`header-list-item full-height ${item.active ? 'active' : ''}`} key={index}>
              <i className={`header-list-icon icon icon-${item.active ? `${item.icon} isLight` : item.icon}`} />
              <span className="header-list-text">{item.name}</span>
            </li>
          );
        })}
      </ul>

      <div className="d-flex header-center">
        <Dropdown name={selectedPolygon ? selectedPolygon.title : ''} className="header-dropdown">
          {areas.map((item) => (
            <DropdownItem onClick={onSelectPolygon.bind(this, item)} key={item.id}>
              {item.title}
            </DropdownItem>
          ))}
        </Dropdown>
        <Dropdown name="Current" className="header-dropdown" disabled={true} />
        <TogglerStilized />
        <Toggler id="toggler" name="toggler" disabled={true} title="Show list view" checked={false} />
      </div>
      <div className="d-flex">
        <button className="header-btn">
          <i className="header-btn-icon icon icon-question isLight" />
        </button>
        <button className="header-btn">
          <i className="header-btn-icon icon icon-forward isLight" onClick={onClickSaveData} data-testid="export-btn" />
        </button>
        <button className="header-btn">
          <i className="header-btn-icon icon icon-bell isLight" />
        </button>
        <Dropdown
          name={currentUser && currentUser.name}
          icon="header-user-icon icon-user isLight"
          className="header-user"
        >
          <DropdownItem onClick={onLogout}>
            Log out <i className="header-logout icon icon-logout isLight" />
          </DropdownItem>
        </Dropdown>
      </div>
      <div className="d-flex">
        <img src={currentUser?.logo || logo} alt="logo" className="header-logo-img" />
      </div>
    </header>
  );
};

export default Header;
