import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapFilter } from './MapFilter';
import { MapSearch } from './MapSearch';
import { MapLayers } from './MapLayers';
import { polygonSelector, resetGripFilter } from '../../map/map.store';
import { IMapService } from '../../map/map.service';
import { fetchPolygonData } from '../../map/map.thunks';
import { resetSelectedGripType, selectedGripTypeSelector } from '../../../store/toolbarOptions';

interface Props {
  mapService: IMapService;
}

export const GlobalControls: React.FC<Props> = (props: Props) => {
  const { mapService } = props;

  const polygon = useSelector(polygonSelector);
  const selectedGripType = useSelector(selectedGripTypeSelector);
  const dispatch = useDispatch();

  const resetMap = () => {
    if (!polygon || !mapService || !selectedGripType) return;
    mapService.showPolygon(polygon.polygon);
    mapService.removePolygonDrawing();
    dispatch(
      fetchPolygonData({
        polygonId: polygon.id,
        dataType: selectedGripType.value,
      }),
    );
    dispatch(resetGripFilter());
    dispatch(resetSelectedGripType());
  };

  return (
    <div className="controls-btn-container global-controls">
      <button className="controls-btn controls-group" data-testid="map-reset" onClick={resetMap}>
        <i className="controls-icon icon icon-home" />
      </button>
      <MapSearch disable={false} mapService={mapService} />
      <MapFilter disable={false} />
      <MapLayers disable={false} />
    </div>
  );
};
