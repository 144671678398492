import React, { useEffect, useState } from 'react';
import { GlobalControls } from './GlobalControls';
import { MapCoords, MapViewPort } from '../map/types';
import { DEFAULT_PITCH, DEFAULT_ZOOM } from '../../../constants/map';
import { Dashboard } from './Dashboard';
import Map from '../map/map.component';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { authErrorSelector, polygonSelector, setAuthError } from '../map/map.store';
import { getCenterOfPolygon } from '../../../helpers/common';
import { IMapService } from '../map/map.service';
import { container } from '../../../configs/inversify';
import { currentUserSelector } from '../auth/auth.store';
import { fetchPolygon } from '../map/map.thunks';
import { logoutUser } from '../auth/auth.thunks';

const mapService: IMapService = container.get('MapService');

const MainPage: React.FC = () => {
  const [initialMapCoords, setInitialMapCoords] = useState<MapCoords | null>(null);
  const authError = useSelector(authErrorSelector);
  const userPolygon = useSelector(polygonSelector);
  const currentUser = useSelector(currentUserSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authError) {
      dispatch(logoutUser());
      dispatch(setAuthError(false));
    }
  }, [authError]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    dispatch(fetchPolygon(currentUser.multipolygon[0].id));
  }, [currentUser]);

  useEffect(() => {
    if (!userPolygon) {
      return;
    }

    const center = getCenterOfPolygon(mapService.getCoordinatesFromPolygonGeometry(userPolygon.polygon));

    setInitialMapCoords({
      lng: center[0],
      lat: center[1],
    });
  }, [userPolygon]);

  const onMapStyleLoad = () => {
    console.log('[DEBUG]: Map loaded');
  };

  const onMapViewportChange = (viewport: MapViewPort) => {
    console.log('[DEBUG]: change map viewport: ', viewport);
  };

  return (
    <div className="map-page">
      <Header />
      <Dashboard />
      <div>
        {initialMapCoords && (
          <>
            <Map
              height="100vh"
              width="100vw"
              mapService={mapService}
              viewport={{
                ...initialMapCoords,
                zoom: DEFAULT_ZOOM,
                pitch: DEFAULT_PITCH,
              }}
              onStyleLoad={onMapStyleLoad}
              onChange={onMapViewportChange}
              theme="mapbox://styles/benz-tactile/ck70azee434jh1iodufvdvakx?optimize=true"
            />
          </>
        )}
      </div>
      <GlobalControls mapService={mapService} />
    </div>
  );
};

export default MainPage;
