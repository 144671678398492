import React, { useEffect, useRef, useState } from 'react';
import { Toggler } from '../../../../components/Toggler';
import cn from 'classnames';

import './_map-layers.scss';

import {
  selectedGripTypeSelector,
  selectedLayerVisibilitySelector,
  setSelectedGripType,
  setSelectedLayerVisibility,
} from '../../../../store/toolbarOptions';
import { useDispatch, useSelector } from 'react-redux';
import { GripTypeOption } from '../../types';
import { makeFirstLatterUppercase } from '../../../../../helpers/common';
interface Props {
  disable: boolean;
}

const gripTypeItems: GripTypeOption[] = [
  {
    title: 'Latest grip',
    value: 'latest',
  },
  {
    title: 'Baseline grip',
    value: 'baseline',
  },
  {
    title: 'Ground truth grip',
    value: 'ground-truth',
  },
];

export const MapLayers: React.FC<Props> = (props: Props) => {
  const { disable } = props;

  const [isActive, setActive] = useState(false);
  const wrapper: React.RefObject<HTMLDivElement> | null = useRef(null);
  const controls: React.RefObject<HTMLDivElement> | null = useRef(null);
  const selectedGripType = useSelector(selectedGripTypeSelector);
  const selectedLayerVisibility = useSelector(selectedLayerVisibilitySelector);
  const dispatch = useDispatch();

  const onSelectGripTypeItem = (gripType: GripTypeOption) => {
    dispatch(setSelectedGripType(gripType));
  };

  const onToggleClick = (e: React.MouseEvent) => {
    if (e.target instanceof Element && !controls.current?.contains(e.target)) {
      dispatch(setSelectedLayerVisibility(!selectedLayerVisibility));
    }
  };

  useEffect(() => {
    if (!selectedGripType) {
      dispatch(setSelectedGripType(gripTypeItems[0]));
    }
  }, [selectedGripType]);

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      e.stopPropagation();
      if (e.target instanceof Element && !wrapper.current?.contains(e.target)) {
        setActive(false);
      }
    };

    if (isActive) {
      document.addEventListener('click', clickHandler);
    }

    return () => document.removeEventListener('click', clickHandler);
  }, [isActive]);

  const handleClick = () => setActive(!isActive);

  return (
    <div className="controls-group" ref={wrapper} data-testid="group">
      <button
        className={`controls-btn ${isActive ? 'active' : ''}`}
        onClick={handleClick}
        data-testid="button"
        {...(disable ? { disabled: true } : '')}
      >
        <i className="controls-icon icon icon-layers" />
      </button>
      <div
        data-testid="container"
        className={cn('controls-container', {
          isVisible: isActive,
          isHidden: !isActive,
          isClosed: !selectedLayerVisibility,
        })}
      >
        {/* <div className="controls-header">
          Select preset
          <i className="controls-header-icon icon icon-arrow" />
        </div> */}
        <div className="map-layers">
          <div className="map-layers-title controls-title">
            Grip
            <Toggler
              id="grip"
              name="grip"
              disabled={false}
              checked={selectedLayerVisibility}
              title={selectedLayerVisibility ? 'On' : 'Off'}
              onClick={onToggleClick}
              className="map-layers-toggler"
            />
          </div>
          <div className="map-layers-controls controls-body" ref={controls}>
            {gripTypeItems.map((item) => (
              <button
                disabled={!selectedLayerVisibility}
                className={cn('map-layers-btn btn btn-primary', {
                  'btn-selected': selectedGripType && selectedGripType.value === item.value,
                })}
                onClick={onSelectGripTypeItem.bind(this, item)}
                key={item.title}
                role="layer-btn"
              >
                {makeFirstLatterUppercase(item.value)}
              </button>
            ))}
          </div>
        </div>
        <div className="controls-footer">
          {/* <button className="controls-footer-btn" disabled>
            Save preset
          </button> */}
          <button className="controls-footer-btn">Reset</button>
        </div>
      </div>
    </div>
  );
};
