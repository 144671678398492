import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_ROUTE;

export const request = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
