import React from 'react';

interface Props {
  onClickCursor?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onClickTarget?: () => void;
  onChangeTo3d?: () => void;
}

export const MapControls: React.FC<Props> = (props: Props) => {
  const { onClickCursor, onZoomIn, onZoomOut, onClickTarget } = props;

  return (
    <div className="controls-btn-container map-controls">
      <button className="controls-btn" onClick={onClickCursor} data-testid="map-cursor">
        <i className="controls-icon icon icon-cursor" />
      </button>
      <div className="controls-solid">
        <button className="controls-solid-btn" onClick={onZoomIn} id="map-zoomIn" data-testid="map-zoomIn">
          <i className="controls-icon icon icon-plus" />
        </button>

        <button className="controls-solid-btn" onClick={onZoomOut} id="map-zoomOut" data-testid="map-zoomOut">
          <i className="controls-icon icon icon-minus" />
        </button>
      </div>

      <button className="controls-btn" onClick={onClickTarget} id="map-target" data-testid="map-target">
        <i className="controls-icon icon icon-target" />
      </button>
    </div>
  );
};
