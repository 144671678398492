import React, { PropsWithChildren } from 'react';

interface Props {
  // eslint-disable-next-line
  onClick?: (e: any) => void;
}

export const DropdownItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className="dropdown-item" onClick={props.onClick}>
      {props.children}
    </div>
  );
};
