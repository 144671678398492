import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  DEFAULT_3D_MAX_ZOOM,
  DEFAULT_3D_PITCH,
  DEFAULT_MAX_ZOOM,
  DEFAULT_PITCH,
  DEFAULT_ZOOM,
} from '../../../constants/map';
import { FilterOption, Grip, MapCoords, MapViewPort } from './types';
import { GripType } from '../main/types';
import {
  BASELINE_GRIP_DATA_HIGH_VALUE,
  BASELINE_GRIP_DATA_LOW_VALUE,
  GRIP_DATA_HIGH_VALUE,
  GRIP_DATA_LOW_VALUE,
} from '../../../constants/dataParms';
import { getCenterOfPolygon, getCustomPolygonWKTString } from '../../../helpers/common';
import { injectable } from 'inversify';
import 'reflect-metadata';

export interface IMapService {
  setMap(map: mapboxgl.Map): void;
  getMap(): mapboxgl.Map | null | undefined;
  enableMapControls(): void;
  getMapCoordinates(): MapViewPort | null;
  getCoordinatesFromPolygonGeometry(geometry: string): number[][];
  changeZoom(isIncrease: boolean): void;
  changeTo3d(): void;
  hideNonRelevantContainers(element: HTMLElement): void;
  navigate(coords: MapCoords): void;
  startCustomPolygonSelection(): void;
  showDataLayer(gripData: Grip[], gripType: GripType, filter: FilterOption): void;
  showPolygon(polygon: string): void;
  removePolygonDrawing(): void;
  addPolygonDrawing(): void;
}

@injectable()
class MapService implements IMapService {
  private map: mapboxgl.Map | null;
  private drawingControl: MapboxDraw | null;
  private GRIP_NAME = 'grip';
  private GRIP_TYPE_LOW = 'red';
  private GRIP_TYPE_MEDIUM = 'yellow';
  private GRIP_TYPE_HIGH = 'green';

  private isStyleIn3d = false;
  private isDuringCustomSelection = false;
  private referenceToUpdateCustomPolygon: () => void;

  public setMap(map: mapboxgl.Map): void {
    this.map = map;
  }

  public getMap(): mapboxgl.Map | null | undefined {
    return this.map;
  }

  public enableMapControls(): void {
    if (!this.map) return;

    this.map.keyboard.enable();
    this.map.keyboard.enableRotation();
    this.map['boxZoom'].enable();
  }

  public startCustomPolygonSelection(): void {
    if (this.isDuringCustomSelection) {
      this.removePolygonDrawing();
    } else {
      this.addPolygonDrawing();
    }
  }

  public addPolygonDrawing(): void {
    if (!this.map || this.drawingControl) return;

    const drawingControl = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_polygon',
    });

    this.drawingControl = drawingControl;
    this.map.addControl(drawingControl);
    this.isDuringCustomSelection = true;

    if (this.referenceToUpdateCustomPolygon) {
      this.map.off('draw.create', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.delete', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.update', this.referenceToUpdateCustomPolygon);
    }
    this.referenceToUpdateCustomPolygon = this.updateCustomPolygon.bind(this);
    this.map.on('draw.create', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.delete', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.update', this.referenceToUpdateCustomPolygon);
  }

  private updateCustomPolygon(): void {
    const geometry = this.drawingControl?.getAll().features[0].geometry as { coordinates: number[][] };
    const polygonString = getCustomPolygonWKTString(geometry?.coordinates);
    console.log('[DEBUG]: Selected polygon: ', polygonString);
  }

  public removePolygonDrawing(): void {
    if (!this.map || !this.drawingControl) return;
    this.map.removeControl(this.drawingControl);
    this.drawingControl = null;
    this.isDuringCustomSelection = false;
  }

  public getMapCoordinates(): MapViewPort | null {
    if (!this.map) return null;

    const lng = parseFloat(this.map.getCenter().lng.toFixed(4));
    const lat = parseFloat(this.map.getCenter().lat.toFixed(4));
    const zoom = parseFloat(this.map.getZoom().toFixed(2));
    const pitch = parseFloat(this.map.getPitch().toFixed(2));
    return {
      lng,
      lat,
      zoom,
      pitch,
    };
  }

  public changeZoom(isIncrease: boolean): void {
    if (!this.map) return;

    const currentZoom = this.map.getZoom();
    if (!currentZoom) {
      return;
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      zoom: isIncrease ? currentZoom + 1 : currentZoom - 1,
    });
  }

  public changeTo3d(): void {
    if (!this.map) return;
    let newPitch: number;

    if (this.isStyleIn3d) {
      newPitch = DEFAULT_PITCH;
      this.map.setMaxZoom(DEFAULT_MAX_ZOOM);
    } else {
      newPitch = DEFAULT_3D_PITCH;
      this.map.setMaxZoom(DEFAULT_3D_MAX_ZOOM);
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      pitch: newPitch,
    });

    this.isStyleIn3d = !this.isStyleIn3d;
  }

  public hideNonRelevantContainers(mapElement: HTMLElement | null): void {
    if (!mapElement) {
      return;
    }

    const container = mapElement.querySelector('.mapboxgl-control-container');
    if (container) {
      container.remove();
    }
  }

  public navigate(coords: MapCoords): void {
    if (!this.map) return;
    this.map.flyTo({
      center: coords,
      zoom: DEFAULT_ZOOM,
    });
  }

  public showPolygon(polygon: string): void {
    if (!this.map) return;
    const coords = this.getCoordinatesFromPolygonGeometry(polygon);
    this.addMapLayerForBasePolygon(coords);
    const centerCoords = getCenterOfPolygon(coords);
    this.navigate({
      lat: centerCoords[1],
      lng: centerCoords[0],
    });
  }

  public showDataLayer(gripData: Grip[], gripType: GripType, filter: FilterOption): void {
    this.showDataLayerForGrip(gripData, gripType, filter);
  }

  private shiftLayer(filterOption: boolean, data: number[][][], color: string): void {
    if (filterOption) {
      this.addMapLayerForDataLines(data, color);
    } else {
      this.removeMapLayer(`${this.GRIP_NAME} ${color}`);
    }
  }

  private showDataLayerForGrip(gripData: Grip[], gripType: GripType, filter: FilterOption): void {
    const { low, medium, high } = this.parseDataToLevels(gripData, gripType);
    this.shiftLayer(filter.low, low, this.GRIP_TYPE_LOW);
    this.shiftLayer(filter.medium, medium, this.GRIP_TYPE_MEDIUM);
    this.shiftLayer(filter.high, high, this.GRIP_TYPE_HIGH);
  }

  private parseDataToLevels(
    gripData: Grip[],
    gripType: GripType,
  ): {
    low: number[][][];
    medium: number[][][];
    high: number[][][];
  } {
    const highValue = gripType === 'latest' ? GRIP_DATA_HIGH_VALUE : BASELINE_GRIP_DATA_HIGH_VALUE;
    const lowValue = gripType === 'baseline' ? GRIP_DATA_LOW_VALUE : BASELINE_GRIP_DATA_LOW_VALUE;

    const redGrips = gripData
      .filter((dataItem) => dataItem.available_grip <= lowValue)
      .map((item) => this.getCoordinatesFromGripDataGeometry(item.geometry));
    const yellowGrips = gripData
      .filter((dataItem) => dataItem.available_grip >= lowValue && dataItem.available_grip <= highValue)
      .map((item) => this.getCoordinatesFromGripDataGeometry(item.geometry));
    const greenGrips = gripData
      .filter((dataItem) => dataItem.available_grip >= highValue)
      .map((item) => this.getCoordinatesFromGripDataGeometry(item.geometry));

    return {
      low: redGrips,
      medium: yellowGrips,
      high: greenGrips,
    };
  }

  public getCoordinatesFromPolygonGeometry(geometry: string): number[][] {
    const arr = [];
    const coordsString = geometry.slice(9, geometry.length - 1);
    const dividedCoords = coordsString.split(',');
    arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
    return arr;
  }

  private getCoordinatesFromGripDataGeometry(geometry: string): number[][] {
    const arr = [];
    const coordsString = geometry.slice(11, geometry.length - 1);
    const dividedCoords = coordsString.split(',');
    arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
    return arr;
  }

  private addMapLayerForDataLines(coords: number[][][], color: string): void {
    const layer = {
      id: `grip ${color}`,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'MultiLineString',
                properties: {},
                coordinates: coords,
              },
            },
          ],
        },
      },
      paint: {
        'line-width': 5,
        'line-color': color,
      },
    };
    this.addMapLayer(layer);
  }

  private addMapLayerForBasePolygon(coords: number[][]): void {
    const polygonBorder = {
      id: 'base polygon',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coords],
          },
          properties: {},
        },
      },
      paint: {
        'line-color': '#1E5A8C',
        'line-width': 3,
      },
    };

    const polygonFill = {
      id: 'base polygon fill',
      type: 'fill',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coords],
          },
          properties: {},
        },
      },
      paint: {
        'fill-opacity': 0.05,
        'fill-color': '#ff7500',
      },
    };

    this.addMapLayer(polygonBorder);
    this.addMapLayer(polygonFill);
  }

  // TODO: add appropriate typing
  // eslint-disable-next-line
  private addMapLayer(mapLayer: any): void {
    if (!this.map) return;

    const existedLayer = this.map.getLayer(mapLayer.id);
    if (existedLayer) {
      this.map.removeLayer(existedLayer.id);
      this.map.removeSource(existedLayer.id);
    }
    this.map.addLayer(mapLayer);
  }

  private removeMapLayer(id: string): void {
    if (!this.map) return;

    const existedLayer = this.map.getLayer(id);
    if (existedLayer) {
      this.map.removeLayer(id);
      this.map.removeSource(id);
    }
  }
}

export default MapService;
