import React, { PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  name?: string;
  icon?: string;
  className?: string;
  disabled?: boolean;
}

export const Dropdown: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { name, icon, className, disabled } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      e.stopPropagation();
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('click', clickHandler);
    }

    return () => document.removeEventListener('click', clickHandler);
  }, [isOpen]);

  return (
    <div
      className={`dropdown ${isOpen ? 'active' : ''} ${className ? className : ''} ${disabled ? 'isDisabled' : ''}`}
      data-testid="dropdown-wrapper"
    >
      <button
        data-testid="dropdown-btn"
        className="dropdown-btn"
        onClick={() => {
          !disabled && setIsOpen(!isOpen);
        }}
      >
        {icon && <i className={`icon ${icon}`} />}
        <span className="dropdown-text">{name}</span>
        <i className="dropdown-btn-icon icon icon-arrow" />
      </button>
      <div className={isOpen ? 'dropdown-group active' : 'dropdown-group'}>{props.children}</div>
    </div>
  );
};
