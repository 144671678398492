import React from 'react';
import FilterButton from '../FilterButton';
import { FilterOption } from '../../../../map/types';
import './_filter.scss';

interface Props {
  title: string;
  filterOptions: FilterOption;
  updateFilterOption: (title: string, value: boolean) => void;
  resetFilters: () => void;
}

export const Filter: React.FC<Props> = (props: Props) => {
  const { title, filterOptions, updateFilterOption } = props;
  const toggleFilterOptionValue = (key: string): void => {
    updateFilterOption(key, !filterOptions[key]);
  };

  return (
    <div className="filter">
      <p className="filter-title">
        {title}
        {/*<button data-testid="resetButton" title="Clear" onClick={resetFilters} className="filter-clear-btn">*/}
        {/*  <i className="filter-clear-icon icon icon-trash isLight" />*/}
        {/*</button>*/}
      </p>
      <div className="filter-btn-container">
        {Object.keys(filterOptions).map((key) => (
          <FilterButton key={key} title={key} handleClick={toggleFilterOptionValue} selected={filterOptions[key]} />
        ))}
      </div>
    </div>
  );
};
