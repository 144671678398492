import React, { Ref, useEffect, useRef, useState } from 'react';
import { FiltersWrapper } from './FiltersWrapper';
import cn from 'classnames';

interface Props {
  disable: boolean;
}

export const MapFilter: React.FC<Props> = (props: Props) => {
  const { disable } = props;
  const [isActive, setActive] = useState(false);
  const wrapper: Ref<HTMLDivElement> = useRef(null);

  const handleClick = () => setActive(!isActive);
  const handleClickAround = (e: MouseEvent) => {
    const { target } = e;
    if (target instanceof Element && !wrapper.current?.contains(target)) {
      handleClick();
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleClickAround);

      return () => document.removeEventListener('click', handleClickAround);
    }
  }, [isActive]);

  return (
    <div className="controls-group" ref={wrapper}>
      <button
        className={`controls-btn ${isActive && 'active'}`}
        onClick={handleClick}
        data-testid="button"
        {...(disable ? { disabled: true } : '')}
      >
        <i className="controls-icon icon icon-filter" />
      </button>
      <div
        data-testid="controls-container"
        className={cn('controls-container', {
          isVisible: isActive,
          isHidden: !isActive,
        })}
      >
        <FiltersWrapper />
      </div>
    </div>
  );
};
