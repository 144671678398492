import React from 'react';

export const FunctionalRoad: React.FC = () => {
  return (
    <div className="dashboard-body dashboard-road">
      <h2 className="dashboard-body-title">Functional road class</h2>
      <div className="dashboard-progress"></div>
    </div>
  );
};
