import React from 'react';
import './_toggler-stilized.scss';

export const TogglerStilized: React.FC = () => {
  return (
    <div className="stilized">
      <input type="radio" id="grips" name="roads" value="grip" className="stilized-input" />
      <input type="radio" id="rating" name="roads" value="rating" className="stilized-input" />
      <input type="radio" id="hazards" name="roads" value="hazards" className="stilized-input" />
      <div className="stilized-btn" />
    </div>
  );
};
