import { searchingArea } from '../app/modules/main/types';
import { Filters } from '../app/modules/map/types';

export const getCenterOfPolygon = (arr: number[][]): [number, number] => {
  let minX, maxX, minY, maxY;
  for (let i = 0; i < arr.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export const getCustomPolygonWKTString = (data: number[][]): string => {
  let res = 'POLYGON((';
  const dataLength = data.length;
  data.forEach((item, i) => {
    res += item.join(' ');
    if (i + 1 < dataLength) {
      res += ',';
    }
  });

  res += '))';
  return res;
};

export const makeFirstLatterUppercase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertBase64ToFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  const file = new File([uint8Array], fileName, { type: mime });
  return file;
};

export const getSearchingArea = (polygonCoordinates: number[][]): searchingArea => {
  const searchingArea: searchingArea = {
    minCoordinates: [],
    maxCoordinates: [],
  };

  polygonCoordinates.forEach((item) => {
    if (!searchingArea.minCoordinates.length) {
      searchingArea.minCoordinates[0] = item[0];
      searchingArea.minCoordinates[1] = item[1];
      searchingArea.maxCoordinates[0] = item[0];
      searchingArea.maxCoordinates[1] = item[1];
    }

    if (searchingArea.minCoordinates[0] > item[0]) {
      searchingArea.minCoordinates[0] = item[0];
    }

    if (searchingArea.minCoordinates[1] > item[1]) {
      searchingArea.minCoordinates[1] = item[1];
    }

    if (searchingArea.maxCoordinates[0] < item[0]) {
      searchingArea.maxCoordinates[0] = item[0];
    }

    if (searchingArea.maxCoordinates[1] < item[1]) {
      searchingArea.maxCoordinates[1] = item[1];
    }
  });

  return searchingArea;
};

export const upFirstLetter = (string: string): string => {
  return string[0].toUpperCase() + string.substring(1);
};

export const parseFiltersToQueryParams = (filters: Filters): string => {
  let filtersStringify = '?';

  for (const filter in filters) {
    if (filters.hasOwnProperty(filter)) {
      for (const option in filters[filter]) {
        if (filters[filter].hasOwnProperty(option) && filters[filter][option]) {
          filtersStringify += `${filter}=${option}&`;
        }
      }
    }
  }

  return filtersStringify.length > 1 ? filtersStringify.substr(0, filtersStringify.length - 1) : '';
};
