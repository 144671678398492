import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { UserMultipolygon } from '../../../types';
import { GripTypeOption } from '../../modules/main/types';

interface ToolbarOptionsState {
  selectedPolygon?: UserMultipolygon;
  selectedGripType?: GripTypeOption;
  selectedLayerVisibility: boolean;
}

const initialState: ToolbarOptionsState = {
  selectedPolygon: undefined,
  selectedGripType: undefined,
  selectedLayerVisibility: true,
};

export const toolbarOptionsSlice = createSlice({
  name: 'toolbarOptions',
  initialState,
  reducers: {
    setSelectedPolygon: (state, action) => {
      state.selectedPolygon = action.payload;
    },
    setSelectedGripType: (state, action) => {
      state.selectedGripType = action.payload;
    },
    setSelectedLayerVisibility: (state, action) => {
      state.selectedLayerVisibility = action.payload;
    },
    resetSelectedGripType: (state) => {
      state.selectedGripType = initialState.selectedGripType;
    },
  },
});

export const { setSelectedPolygon, setSelectedGripType, setSelectedLayerVisibility, resetSelectedGripType } =
  toolbarOptionsSlice.actions;

export function toolbarOptionsSelector(state: RootState): ToolbarOptionsState {
  return state.toolbarOptions;
}

export function selectedPolygonSelector(state: RootState): UserMultipolygon | undefined {
  return state.toolbarOptions.selectedPolygon;
}

export function selectedGripTypeSelector(state: RootState): GripTypeOption | undefined {
  return state.toolbarOptions.selectedGripType;
}

export function selectedLayerVisibilitySelector(state: RootState): boolean {
  return state.toolbarOptions.selectedLayerVisibility;
}

export default toolbarOptionsSlice.reducer;
