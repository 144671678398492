import { GripType } from '../modules/main/types';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { saveAs } from 'file-saver';
import { io, Socket } from 'socket.io-client';
import { request } from '../../helpers/request';
import {
  API_EXPORT_DATA_AS_CSV,
  API_GRIP_BASELINE,
  API_GRIP_GROUND_TRUTH,
  API_GRIP_LATEST,
  API_POLYGON,
} from '../../constants/api';
import { FilterOption, Filters, Grip, Polygon } from '../modules/map/types';
import { AxiosResponse } from 'axios';
import { APIFileResponse, APIResponse, SocketDataUpdateMessage } from '../../types';
import { convertBase64ToFile, parseFiltersToQueryParams } from '../../helpers/common';

export interface IDataService {
  fetchPolygonById(id: number): Promise<Polygon>;
  fetchDataByRegionId(id: number, gripType: GripType): Promise<Grip[]>;
  exportAsCSV(regionId: number, dataType: GripType, filters: { [key: string]: FilterOption }): Promise<void>;
  subscribeToDataChanges(regionId: number, callback: (data: SocketDataUpdateMessage) => void): Socket;
  unsubscribeFromDataChanges(socket: Socket): void;
}

@injectable()
export class DataService implements IDataService {
  public async fetchPolygonById(id: number): Promise<Polygon> {
    const res = (await request.get(`${API_POLYGON}/?polygon_id=${id}`)) as AxiosResponse<APIResponse<Polygon>>;

    if (res.data.status !== 'SUCCESS') {
      throw Error(res.data.error);
    }

    return res.data.item;
  }

  public async fetchDataByRegionId(id: number, gripType: GripType): Promise<Grip[]> {
    let url = '';
    switch (gripType) {
      case 'latest':
        url = API_GRIP_LATEST;
        break;
      case 'baseline':
        url = API_GRIP_BASELINE;
        break;
      case 'ground-truth':
        url = API_GRIP_GROUND_TRUTH;
        break;
    }

    if (!url) {
      return [];
    }

    const res = (await request.get(`${url}/${id}`)) as AxiosResponse<APIResponse<Grip[]>>;

    if (res.data.status !== 'SUCCESS' || !res.data.data) {
      throw Error('Something went wrong while fetching data by polygon');
    }

    return res.data.data;
  }

  public async exportAsCSV(regionId: number, dataType: GripType, filters: Filters): Promise<void> {
    const queryParams = parseFiltersToQueryParams(filters);
    const endpoint = API_EXPORT_DATA_AS_CSV.replace(':layer', dataType).replace(':region_id', `${regionId}`);
    const data = (await request.get(endpoint + queryParams)) as AxiosResponse<APIFileResponse>;

    if (data.data.status !== 'SUCCESS') {
      console.error(`Error while downloading ${dataType} csv report for region ${regionId}`);
      return;
    }

    const file = convertBase64ToFile(`data:text/csv;base64,${data.data.file}`, data.data.file_name);
    saveAs(file, data.data.file_name);
  }

  public subscribeToDataChanges(regionId: number, callback: (data: SocketDataUpdateMessage) => void): Socket {
    const socket = io(process.env.REACT_APP_SOCKET_ROUTE || '/ws', {
      transports: ['polling'],
    });

    socket.on('connect', () => {
      socket.emit('join', { polygon_id: regionId });
    });

    socket.on('message', (msg: string) => {
      console.log('[DEBUG]: message from socket:', msg);
    });

    socket.on('message', (msg: SocketDataUpdateMessage) => {
      if (msg.is_updated) {
        callback(msg);
      }
    });

    return socket;
  }

  public unsubscribeFromDataChanges(socket: Socket): void {
    socket.close();
  }
}
