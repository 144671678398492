import React, { FormEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, clearState } from '../auth.store';
import { LoginFormErrors, LoginFormFields } from '../types';
import { IAuthService } from '../auth.service';
import tactile from '../../../../assets/img/tactile.png';
import TextInput from '../../../components/inputs/TextInput';
import { ILocalStorageService } from '../../../services/LocalStorageService';
import { container } from '../../../../configs/inversify';
import { MAP_PAGE } from '../../../../constants/pathnames';
import { loginUser } from '../auth.thunks';
import './_login.scss';

const initialForm: LoginFormFields = {
  username: '',
  password: '',
  saveCredentials: false,
};

const initialErrors: LoginFormErrors = {
  username: '',
  password: '',
};

const authService: IAuthService = container.get('AuthService');
const localStorageService: ILocalStorageService = container.get('LocalStorageService');

const Login: React.FC = () => {
  const [form, setForm] = useState<LoginFormFields>(initialForm);
  const [errors, setErrors] = useState<LoginFormErrors>(initialErrors);

  const dispatch = useDispatch();
  const history = useHistory();

  // TODO: use "isLoading" variable for showing of loader
  const { isSuccess, isError } = useSelector(authSelector);

  useEffect(() => {
    setForm(localStorageService.getItem('login-credentials') || initialForm);
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(clearState());

    if (isError) {
      setErrors({
        username: 'Field is not valid',
        password: 'Field is not valid',
      });
    }

    if (isSuccess) {
      history.push(MAP_PAGE);
    }
  }, [isSuccess, isError]);

  const onChangeForm = (field: string, value: string | boolean) => {
    setErrors((prev) => ({
      ...prev,
      [field]: '',
    }));

    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!authService.validateFields(form)) {
      setErrors({
        username: 'Field is invalid',
        password: 'Field is invalid',
      });
    }
    dispatch(loginUser(form));
  };

  return (
    <div className="login-page">
      <div className="login">
        <form className="login-form form" onSubmit={onSubmit}>
          <h2 className="login-title">Login</h2>
          <TextInput
            name="username"
            label="Username"
            onChange={onChangeForm.bind(this, 'username')}
            value={form.username}
            error={errors.username}
            placeholder="Username"
            className="login-input username-input"
          >
            <i className="login-icon icon icon-user isLight" />
          </TextInput>
          <TextInput
            name="password"
            label="Password"
            type="password"
            onChange={onChangeForm.bind(this, 'password')}
            value={form.password}
            error={errors.password}
            placeholder="Password"
            className="login-input password-input"
          >
            <i className="login-icon icon icon-email isLight" />
          </TextInput>
          <label className={`login-credential ${form.saveCredentials ? 'is-checked' : ''}`} id="save-credentials">
            <input
              type="checkbox"
              className="hidden save-credentials"
              checked={form.saveCredentials}
              onChange={(e) => onChangeForm('saveCredentials', e.target.checked)}
            />
            Save credentials
          </label>
          <input
            data-testid="login-btn"
            type="submit"
            value="Go"
            onSubmit={onSubmit}
            className="login-btn check-input"
            id="login-submit"
          />
        </form>
      </div>
      <span className="logo-powered">
        <img src={tactile} alt="logo" className="logo-powered-img" />
      </span>
    </div>
  );
};

export default Login;
