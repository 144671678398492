import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import { debounce } from 'lodash';
import { IMapService } from './map.service';
import { MapViewPort } from './types';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, DEFAULT_ZOOM, DEFAULT_MAX_3D_PITCH } from '../../../constants/map';
import { selectedGripTypeSelector, selectedLayerVisibilitySelector } from '../../store/toolbarOptions';
import { clearPolygonData, gripFilterSelector, polygonDataSelector, polygonSelector } from './map.store';
import { MapControls } from './MapControls';
import { fetchPolygonData } from './map.thunks';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_KEY || '';

interface Props {
  theme: string;
  viewport: MapViewPort;
  width: string;
  height: string;
  mapService: IMapService;
  onStyleLoad?: (map: mapboxgl.Map) => void;
  onChange?: (viewport: MapViewPort) => void;
  defaultZoom?: number;
  minZoom?: number;
  maxZoom?: number;
  maxPitch?: number;
}

const Map: React.FC<Props> = (props: Props) => {
  const {
    theme,
    viewport,
    width,
    height,
    mapService,
    defaultZoom = DEFAULT_ZOOM,
    minZoom = DEFAULT_MIN_ZOOM,
    maxZoom = DEFAULT_MAX_ZOOM,
    maxPitch = DEFAULT_MAX_3D_PITCH,
    onStyleLoad,
    onChange,
  } = props;

  const mapContainer = useRef<HTMLDivElement>(null);
  const [mapViewport, setMapViewport] = useState(viewport);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);

  const selectedLayerVisibility = useSelector(selectedLayerVisibilitySelector);
  const selectedGripType = useSelector(selectedGripTypeSelector);
  const polygon = useSelector(polygonSelector);
  const data = useSelector(polygonDataSelector);
  const gripFilter = useSelector(gripFilterSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!polygon || !mapInstance) return;
    mapService.showPolygon(polygon.polygon);
  }, [polygon, mapInstance]);

  useEffect(() => {
    if (!data || !mapInstance || !selectedGripType || !gripFilter) return;
    mapService.showDataLayer(data, selectedGripType.value, gripFilter);
  }, [data, mapInstance, selectedGripType, gripFilter]);

  const onMoveMap = debounce(() => {
    const newViewport = mapService.getMapCoordinates();

    if (!newViewport) return;

    setMapViewport(newViewport);

    if (onChange) {
      onChange(newViewport);
    }
  }, 500);

  useEffect(() => {
    if (!mapContainer || !mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current || 'map',
      style: theme,
      zoom: mapViewport.zoom || defaultZoom,
      center: [mapViewport.lng, mapViewport.lat],
      maxZoom,
      minZoom,
      maxPitch,
    });

    mapService.hideNonRelevantContainers(mapContainer.current);

    map.on('load', () => {
      mapService.setMap(map);
      mapService.enableMapControls();
      setMapInstance(map);
    });

    map.on('click', (e: mapboxgl.MapMouseEvent) => {
      console.log('[DEBUG]: click on the map with coords', e.lngLat);
    });

    map.on('style.load', () => {
      if (onStyleLoad) {
        onStyleLoad(map);
      }
    });

    map.on('move', () => onMoveMap());

    mapContainer.current.onkeydown = (e) => {
      if (e.key === 'Control') {
        mapService.addPolygonDrawing();
      } else if (e.key === 'Delete') {
        mapService.removePolygonDrawing();
      }
    };

    mapContainer.current.onkeyup = (e) => {
      if (e.key === 'Control') {
        mapService.removePolygonDrawing();
      }
    };
  }, [viewport, mapContainer]);

  useEffect(() => {
    if (selectedLayerVisibility) {
      if (polygon && selectedGripType) {
        dispatch(
          fetchPolygonData({
            polygonId: polygon.id,
            dataType: selectedGripType.value,
          }),
        );
      }
    } else {
      dispatch(clearPolygonData());
    }
  }, [selectedLayerVisibility]);

  const onClickCursor = () => {
    mapService.startCustomPolygonSelection();
  };

  const onClickZoom = (isIncrease: boolean) => {
    mapService.changeZoom(isIncrease);
  };

  const onClickTarget = () => {
    if (polygon) {
      mapService.showPolygon(polygon.polygon);
    }
  };

  return (
    <div>
      <div className="map" id="map" ref={mapContainer} style={{ height, width }} />
      <MapControls
        onClickCursor={onClickCursor}
        onClickTarget={onClickTarget}
        onChangeTo3d={mapService.changeTo3d}
        onZoomIn={onClickZoom.bind(this, true)}
        onZoomOut={onClickZoom.bind(this, false)}
      />
    </div>
  );
};

export default Map;
