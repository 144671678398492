import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchMapDataType } from './types';
import { container } from '../../../configs/inversify';
import { IDataService } from '../../services/DataService';
import { API_AUTH_ERROR } from '../../../constants/errors';

const dataService: IDataService = container.get('DataService');

export const fetchUserPolygon = createAsyncThunk('map/fetchUserPolygon', async (id: number) => {
  return dataService.fetchPolygonById(id);
});

export const fetchPolygon = createAsyncThunk('map/fetchPolygon', async (id: number, { rejectWithValue }) => {
  try {
    return await dataService.fetchPolygonById(id);
  } catch (e) {
    if (e.message === API_AUTH_ERROR) {
      return rejectWithValue(true);
    }
  }
});

export const fetchPolygonData = createAsyncThunk('map/fetchData', async ({ polygonId, dataType }: FetchMapDataType) => {
  return dataService.fetchDataByRegionId(polygonId, dataType);
});
