import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LOGIN, MAP_PAGE } from '../constants/pathnames';
import Login from '../app/modules/auth/login/login.component';
import MainPage from '../app/modules/main/main.component';
import AuthenticatedRoute from '../app/components/AuthenticatedRoute';

const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={LOGIN} component={Login} />
        <AuthenticatedRoute exact path={MAP_PAGE} component={MainPage} />
      </Switch>
    </Router>
  );
};

export default Routing;
