import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Map } from 'mapbox-gl';
import { RootState } from '../../store';
import { fetchPolygon, fetchPolygonData } from './map.thunks';
import { Grip, FilterOption, Polygon, Filters } from './types';
import { logoutUser } from '../auth/auth.thunks';

interface MapState {
  selectedPolygon: Polygon | null;
  selectedPolygonData: Grip[];
  currentMap: Map | null;
  filters: Filters;
  authError: boolean;
}

export const initialState: MapState = {
  selectedPolygon: null,
  selectedPolygonData: [],
  currentMap: null,
  filters: {
    gripFilter: {
      high: true,
      medium: true,
      low: true,
    },
  },
  authError: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clearPolygonData: (state) => {
      state.selectedPolygonData = [];
    },
    setGripFilter: (state, action: PayloadAction<FilterOption>) => {
      state.filters.gripFilter = action.payload;
    },
    resetGripFilter: (state) => {
      state.filters.gripFilter = initialState.filters.gripFilter;
    },
    setAuthError: (state, action: PayloadAction<boolean>) => {
      state.authError = action.payload;
    },
  },
  extraReducers: {
    [fetchPolygon.fulfilled.type]: (state, action: PayloadAction<Polygon>) => {
      state.selectedPolygon = action.payload;
    },
    [fetchPolygon.rejected.type]: (state, action: PayloadAction<boolean>) => {
      state.authError = action.payload;
    },
    [fetchPolygonData.fulfilled.type]: (state, action: PayloadAction<Grip[]>) => {
      state.selectedPolygonData = action.payload;
    },
    [logoutUser.fulfilled.type]: (state) => {
      state.selectedPolygon = null;
      state.selectedPolygonData = [];
    },
  },
});

export const { clearPolygonData, resetGripFilter, setGripFilter, setAuthError } = mapSlice.actions;

export function mapSelector(state: RootState): MapState {
  return state.map;
}

export function polygonSelector(state: RootState): Polygon | null {
  return state.map.selectedPolygon;
}

export function authErrorSelector(state: RootState): boolean {
  return state.map.authError;
}

export function polygonDataSelector(state: RootState): Grip[] | null {
  return state.map.selectedPolygonData;
}

export function filtersSelector(state: RootState): Filters {
  return state.map.filters;
}

export function gripFilterSelector(state: RootState): FilterOption {
  return state.map.filters.gripFilter;
}

export default mapSlice.reducer;
