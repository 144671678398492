import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from '../../modules/auth/auth.store';
import { LOGIN } from '../../../constants/pathnames';

type Props = RouteProps;

const AuthenticatedRoute: React.FC<Props> = (props: Props) => {
  const currentUser = useSelector(authSelector).isAuthorized;
  return currentUser ? <Route {...props} /> : <Redirect to={LOGIN} />;
};

export default AuthenticatedRoute;
