import { injectable } from 'inversify';
import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { APIResponse, LoginCredentials, User } from '../../../types';
import { LoginFormFields } from './types';
import { request } from '../../../helpers/request';
import { API_LOGIN, API_LOGOUT } from '../../../constants/api';

export interface IAuthService {
  validateFields(form: LoginFormFields): boolean;
  login(credentials: LoginCredentials): Promise<User>;
  logout(): Promise<void>;
}

@injectable()
export class AuthService implements IAuthService {
  public async login(credentials: LoginCredentials): Promise<User> {
    const res = (await request.post(API_LOGIN, credentials)) as AxiosResponse<APIResponse<User>>;
    if (res.data.status !== 'SUCCESS' || !res.data.currentUser) {
      throw Error('Something went wrong while logging in');
    }
    return res.data.currentUser;
  }

  public validateFields(form: LoginFormFields): boolean {
    const { username, password } = form;
    let isUsernameError = false;
    let isPasswordError = false;

    if (username.trim() === '') {
      isUsernameError = true;
    }

    if (password.trim() === '') {
      isPasswordError = true;
    }

    return !isPasswordError && !isUsernameError;
  }

  public async logout(): Promise<void> {
    await request.get(API_LOGOUT);
  }
}
