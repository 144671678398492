import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { gripFilterSelector, polygonDataSelector, polygonSelector } from '../../map/map.store';
import {
  BASELINE_GRIP_DATA_HIGH_VALUE,
  BASELINE_GRIP_DATA_LOW_VALUE,
  GRIP_DATA_HIGH_VALUE,
  GRIP_DATA_LOW_VALUE,
} from '../../../../constants/dataParms';
import { selectedGripTypeSelector, selectedLayerVisibilitySelector } from '../../../store/toolbarOptions';
import './_dashboard.scss';
import { makeFirstLatterUppercase } from '../../../../helpers/common';
import { FunctionalRoad } from '../../../components/FunctionalRoad';

type DashboardState = {
  title: string;
  subtitle: string;
  mode: 'single' | 'many';
  high: number;
  medium: number;
  low: number;
};

export const Dashboard: React.FC = () => {
  const [dashboardState, setDashboardState] = useState<DashboardState>({
    title: '',
    subtitle: '',
    mode: 'many',
    high: 0,
    medium: 0,
    low: 0,
  });

  const data = useSelector(polygonDataSelector);
  const gripType = useSelector(selectedGripTypeSelector);
  const polygon = useSelector(polygonSelector);
  const selectedLayerVisibility = useSelector(selectedLayerVisibilitySelector);
  const gripFilter = useSelector(gripFilterSelector);

  useEffect(() => {
    if (!data || !gripType || !polygon) return;

    const mode = gripType.value === 'ground-truth' ? 'single' : 'many';

    const highValue = gripType.value === 'latest' ? GRIP_DATA_HIGH_VALUE : BASELINE_GRIP_DATA_HIGH_VALUE;
    const lowValue = gripType.value === 'baseline' ? GRIP_DATA_LOW_VALUE : BASELINE_GRIP_DATA_LOW_VALUE;

    const allDataCount = data.length;
    const dataWithGripValue = data.filter((item) => item.available_grip);

    const lowGrips = dataWithGripValue.filter((dataItem) => dataItem.available_grip <= lowValue);
    const mediumGrips = dataWithGripValue.filter(
      (dataItem) => dataItem.available_grip >= lowValue && dataItem.available_grip <= highValue,
    );
    const highGrips = dataWithGripValue.filter((dataItem) => dataItem.available_grip >= highValue);

    setDashboardState({
      title: `${polygon.title} - ${makeFirstLatterUppercase(gripType.value)}`,
      subtitle: `${makeFirstLatterUppercase(gripType.value)} Grip Breakdown`,
      high: gripFilter.high && allDataCount ? parseInt(String((highGrips.length * 100) / allDataCount)) : 0,
      medium: gripFilter.medium && allDataCount ? parseInt(String((mediumGrips.length * 100) / allDataCount)) : 0,
      low: gripFilter.low && allDataCount ? parseInt(String((lowGrips.length * 100) / allDataCount)) : 0,
      mode,
    });
  }, [data, gripType, polygon, gripFilter]);

  const [isDashboardOpen, setDashboardOpen] = useState(true);

  const toggleDashboard = () => setDashboardOpen(!isDashboardOpen);

  return !selectedLayerVisibility ? (
    <></>
  ) : (
    <>
      <div className="controls-btn-container statistics-controls">
        <button
          className={`controls-btn ${isDashboardOpen ? 'active' : ''}`}
          data-testid="map-cursor"
          onClick={toggleDashboard}
        >
          <i className="controls-icon icon icon-statistics" />
        </button>
        <button className="controls-btn" data-testid="map-cursor" disabled>
          <i className="controls-icon icon icon-clipboard" />
        </button>
      </div>
      <div className={`dashboard ${isDashboardOpen ? 'isOpen' : 'isClosed'}`}>
        <div className="dashboard-body">
          <h2 className="dashboard-body-title">{dashboardState.subtitle}</h2>
          <i className="dashboard-arrow-icon icon icon-arrow" onClick={toggleDashboard} />
          <div className="dashboard-progress" data-testid="progress-bars">
            <div>
              <CircularProgressbar
                value={dashboardState.high}
                text={`${dashboardState.high}%`}
                className="progressbar"
                strokeWidth={12}
                styles={buildStyles({
                  textColor: '#ffffff',
                  pathColor: '#37bc9b',
                  trailColor: '#181818',
                  strokeLinecap: 'butt',
                })}
              />
              <span className="dashboard-progress-annotation">High</span>
            </div>
            {dashboardState.mode === 'many' && (
              <>
                <div>
                  <CircularProgressbar
                    value={dashboardState.medium}
                    text={`${dashboardState.medium}%`}
                    className="progressbar"
                    strokeWidth={12}
                    styles={buildStyles({
                      textColor: '#ffffff',
                      pathColor: '#f6bb42',
                      trailColor: '#181818',
                      strokeLinecap: 'butt',
                    })}
                  />
                  <span className="dashboard-progress-annotation">Medium</span>
                </div>
                <div>
                  <CircularProgressbar
                    value={dashboardState.low}
                    text={`${dashboardState.low}%`}
                    className="progressbar"
                    strokeWidth={12}
                    styles={buildStyles({
                      textColor: '#ffffff',
                      pathColor: '#da4453',
                      trailColor: '#181818',
                      strokeLinecap: 'butt',
                    })}
                  />
                  <span className="dashboard-progress-annotation">Low</span>
                </div>
              </>
            )}
          </div>
        </div>
        <FunctionalRoad />
      </div>
    </>
  );
};

export default Dashboard;
