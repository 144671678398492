import React, { ChangeEvent } from 'react';

interface Props {
  label?: string;
  name: string;
  type?: 'text' | 'password';
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  className?: string;
  children?: React.ReactNode;
}

const TextInput: React.FC<Props> = (props: Props) => {
  const { label, name, type = 'text', placeholder, value, onChange, error, className = '', children } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`form-group ${className}`}>
      <div className="form-field">
        {children}
        <input
          id={name}
          type={type}
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          className={`form-control ${value.length ? 'input-valid' : ''}`}
          data-testid="input-test"
          autoComplete="off"
        />
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      </div>
      {error && <div className="form-validation">{error}</div>}
    </div>
  );
};

export default TextInput;
