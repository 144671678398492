import React from 'react';
import { upFirstLetter } from '../../../../../../helpers/common';
import cn from 'classnames';
interface Props {
  title: string;
  handleClick: (title: string) => void;
  selected?: boolean;
}

const FilterButton: React.FC<Props> = (props: Props) => {
  const { title, selected = false, handleClick } = props;
  const handleEvent = (): void => {
    handleClick(title);
  };

  return (
    <button
      data-testid="filterButton"
      role="button"
      className={cn('filter-btn btn', { 'btn-selected': selected, 'btn-primary': !selected })}
      key={title}
      onClick={handleEvent}
    >
      {upFirstLetter(title)}
    </button>
  );
};

export default FilterButton;
