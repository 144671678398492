import { configureStore, DeepPartial } from '@reduxjs/toolkit';
import { LocalStorageService } from '../services/LocalStorageService';
import AuthReducer from '../modules/auth/auth.store';
import ToolbarOptionsReducer from './toolbarOptions';
import MapReducer from '../modules/map/map.store';

const localStorageService = new LocalStorageService();

const getSavedState = () => {
  return localStorageService.getItem('state') || {};
};

const store = configureStore({
  preloadedState: getSavedState() as DeepPartial<never>,
  reducer: {
    auth: AuthReducer,
    toolbarOptions: ToolbarOptionsReducer,
    map: MapReducer,
  },
});

store.subscribe(() => {
  const state = store.getState();
  localStorageService.setItem('state', {
    auth: state.auth,
  });
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
