import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { polygonSelector } from '../../../map/map.store';
import { IMapService } from '../../../map/map.service';
import { searchingArea } from '../../types';
import { getSearchingArea } from '../../../../../helpers/common';
import { SEARCH_RESULTS_LIMIT } from '../../../../../constants/map';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './_search.scss';

interface Props {
  disable: boolean;
  mapService: IMapService;
}

export const MapSearch: React.FC<Props> = (props: Props) => {
  const { disable, mapService } = props;

  const [isActive, setActive] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const geocoder = useRef<HTMLInputElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const polygon = useSelector(polygonSelector);
  const map = mapService.getMap();

  useEffect(() => {
    if (!map || !polygon || !polygon.polygon) {
      return;
    }

    const mapboxGeocoder: MapboxGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: map,
    });
    const polygonCoordinates: number[][] = mapService.getCoordinatesFromPolygonGeometry(polygon.polygon);
    const searchingArea: searchingArea = getSearchingArea(polygonCoordinates);

    mapboxGeocoder.setLimit(SEARCH_RESULTS_LIMIT);
    mapboxGeocoder.setBbox([
      searchingArea.minCoordinates[0],
      searchingArea.minCoordinates[1],
      searchingArea.maxCoordinates[0],
      searchingArea.maxCoordinates[1],
    ]);
    mapboxGeocoder.addTo('#geocoder');
    mapboxGeocoder.on('result', ({ result: { center } }: { [result: string]: { [center: string]: number[] } }) => {
      map.flyTo({
        center: [center[0], center[1]],
      });
    });
  }, [map]);

  useEffect(() => {
    if (isActive) {
      const clickHandler = (e: MouseEvent) => {
        e.stopPropagation();
        const { target } = e;
        if (
          target instanceof Element &&
          !button.current?.contains(target) &&
          (!geocoder.current?.contains(target) || geocoder.current?.querySelector('ul')?.contains(target))
        ) {
          handleClick();
        }
      };
      const inputNode = geocoder.current?.querySelector('input');

      if (inputNode) {
        inputNode.value = '';
        inputNode.focus();
      }

      document.addEventListener('mouseup', clickHandler);
      return () => document.removeEventListener('mouseup', clickHandler);
    }
  }, [isActive]);

  const handleClick = () => setActive(!isActive);

  return (
    <div className="controls-group search" ref={wrapper}>
      <button
        ref={button}
        className={`controls-btn ${isActive ? 'active' : ''}`}
        onClick={handleClick}
        data-testid="button"
        {...(disable ? { disabled: true } : '')}
      >
        <i className="controls-icon icon icon-search" />
      </button>
      <div className={`controls-container ${isActive ? 'isVisible' : 'isHidden'}`} data-testid="controls-container">
        <div id="geocoder" ref={geocoder} />
      </div>
    </div>
  );
};
