import 'reflect-metadata';
import { Container } from 'inversify';
import { AuthService, IAuthService } from '../app/modules/auth/auth.service';
import MapService, { IMapService } from '../app/modules/map/map.service';
import { ILocalStorageService, LocalStorageService } from '../app/services/LocalStorageService';
import { DataService, IDataService } from '../app/services/DataService';

const container = new Container();
container.bind<IAuthService>('AuthService').to(AuthService);
container.bind<IMapService>('MapService').to(MapService);
container.bind<IDataService>('DataService').to(DataService);
container.bind<ILocalStorageService>('LocalStorageService').to(LocalStorageService);

export { container };
