import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../types';
import { RootState } from '../../store';
import { loginUser, logoutUser } from './auth.thunks';

interface AuthState {
  isAuthorized: boolean;
  currentUser: User | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export const initialState: AuthState = {
  isAuthorized: false,
  currentUser: undefined,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [loginUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.isAuthorized = true;
      state.isSuccess = true;
      state.isLoading = false;
    },
    [loginUser.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [logoutUser.fulfilled.type]: (state) => {
      state.currentUser = undefined;
      state.isAuthorized = false;
    },
    [logoutUser.rejected.type]: (state) => {
      state.currentUser = undefined;
      state.isAuthorized = false;
    },
  },
});

export const { clearState } = authSlice.actions;

export function authSelector(state: RootState): AuthState {
  return state.auth;
}

export function currentUserSelector(state: RootState): User | undefined {
  return state.auth.currentUser;
}

export default authSlice.reducer;
